<template>
  <div
    class="p-3 rounded-md  "
    :class="
      GET_POSITION.id == position.id
        ? 'border-t-4 border-primary'
        : 'border border-transparent'
    "
  >
    <div class="flex items-center gap-2">
      <div class="font-semibold flex gap-2">
        <div>{{ position.name }}</div>
      </div>

      <div
        class="ml-auto rounded-full w-10 h-10 items-center flex hover:bg-primary-lightest"
        @mouseover="state.hover = true"
        @mouseleave="state.hover = false"
        @click.stop="$emit('showPositionsChildren')"
      >
        <div class="cursor-pointer">
          <div id="btn" class="ml-2"><position-icon /></div>
          <div
            class="text-sm mt-2 bg-grey-dark text-white px-1 absolute rounded shadow-xl"
            id="tooltip"
            v-if="state.hover == true"
          >
            {{ $t("show_all_positions") }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-3 text-sm gap-9 sm:gap-20" v-if="position != null">
      <div class="sm:w-auto w-1/2">
        <div class="text-sm text-blue">
          {{ $t("linked_locations") }}
        </div>
        <div class="text-md ">
          {{ position.childrenCount }}
        </div>
      </div>
      <div>
        <div class="text-sm text-blue">
          {{ $t("product_carrier") }}
        </div>
        <div class="text-md " v-if="position.collis != null">
          {{ position.colliCount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import PositionIcon from "../../../components/icons/PositionIcon.vue";
import { CUSTOMER_POSITIONS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
  name: "FinderLocationsContainerContainer",
  components: { PositionIcon },
  /** Emits :  */
  emits: ["showPositionsChildren"],

  /** Props */
  props: {
    position: {
      type: Object,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    /** State */
    const state = reactive({
      hover: {
        type: Boolean,
        default: false,
      },
    });

    /*
     * Computeds
     */
    const GET_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_POSITIONS}/${CUSTOMER_POSITIONS_TYPES.GET_CUSTOMER_POSITION}`
      ];
    });

    return {
      state,
      GET_POSITION,
    };
  },
});
</script>
