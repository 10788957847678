<template>
  <div class="flex items-center mt-4 mb-4 gap-3">
    <searchbar
      v-if="locationFilter == false"
      :id="'search'"
      :label="$t('search')"
      v-model="state.search"
      :labelClass="'bg-white-dark'"
      :activeColor="'primary'"
      class="w-full sm:w-1/3 "
      :placeHolder="$t('search')"
      @update:modelValue="debounceSearchFinderCollis"
    />
    <!--<searchbar
      v-if="locationFilter == true"
      :id="'search'"
      :label="$t('search')"
      v-model="state.search"
      :labelClass="'bg-white-dark'"
      :activeColor="'primary'"
      class="w-full sm:w-1/3 "
      :placeHolder="$t('search')"
      @update:modelValue="(value) => $emit('update', value)"
    />-->

    <input-select
      v-if="locationFilter == true"
      :id="'locations'"
      :activeColor="'bg-black'"
      :defaultColor="'grey-light'"
      :label="$t('locations')"
      :nameColumn="'name'"
      :labelClass="'bg-white rounded-t-lg'"
      :background="'bg-white'"
      :isSearch="true"
      ref="dropdown"
      :activeItems="[state.position]"
      :options="GET_FINDER_POSITIONS"
      class="w-full sm:w-1/3 bg-white rounded-xl"
      @changeItem="
        (item) => {
          state.position = state.position == item ? '' : item;
          state.colliLocation = state.colliLocation == item ? '' : item;
          $refs.dropdown.close();
          $emit('filterPosition', state.position);
          $emit('filterColliLocation', state.colliLocation);
        }
      "
    />
    <input-select
      data-testid="individual_fields"
      v-if="locationFilter == false"
      :id="'individual_fields'"
      class="w-1/3 bg-white rounded-xl"
      :activeColor="'bg-black'"
      :defaultColor="'grey-light'"
      :name="'individual_fields'"
      :label="$t('individual_field')"
      :labelClass="'bg-white rounded-t-lg'"
      :background="'bg-white'"
      :isSearch="true"
      :nameColumn="'key'"
      ref="dropdown"
      :options="GET_DATA_FIELDS"
      :activeItems="[state.dataField]"
      @changeItem="
        (item) => {
          state.dataField = state.dataField == item ? '' : item;
          $emit('filterDataField', state.dataField);
          $refs.dropdown.close();
          debounceSearchFinderCollis();
        }
      "
    />
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import InputSelect from "../../../components/input/InputSelect.vue";
import Debouncer from "../../../common/debouncer";
import {
  AUTH_TYPES,
  DATA_FIELDS_TYPES,
  FINDERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "SearchBox",
  components: { Searchbar, InputSelect },
  emits: [
    "open",
    "update",
    "select",
    "filterPosition",
    "filterColliLocation",
    "filterDataField",
  ],
  props: {
    locationFilter: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      debouncer: new Debouncer(),

      search: "",
      position: "",
      colliLocation: "",
      dataField: "",
    });

    /*
     * Computeds
     */
    const GET_FINDER_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_POSITIONS}`
      ];
    });

    const GET_USER = computed(() => {
      return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`];
    });
    const GET_DATA_FIELDS = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.GET_DATA_FIELDS}`
      ];
    });

    /** Function */
    const debounceSearchFinderCollis = () => {
      state.debouncer.debounce(FETCH_FINDER_COLLIS);
    };

    function FETCH_FINDER_COLLIS() {
      const finderCollisData = {
        id: GET_USER.value.customer_id,
        params: {
          search: state.search,
          field: state.dataField ? state.dataField.key : null,
          page: 1,
        },
      };
      return store.dispatch(
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.FETCH_FINDER_COLLIS}`,
        finderCollisData
      );
    }

    return {
      state,
      route,
      GET_FINDER_POSITIONS,
      GET_DATA_FIELDS,
      debounceSearchFinderCollis,
    };
  },
};
</script>
