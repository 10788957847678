<template>
  <div>
    <bottom-up-modal-2 :isVisible="isVisible" @closeModal="$emit('closeModal')">
      <div
        class="text-lg font-semibold justify-center flex gap-2"
        v-if="GET_COLLI != null"
      >
        <div class="text-primary">{{ GET_COLLI.name }}</div>
        <div class="text-primary">{{ $t("content") }}</div>
      </div>

      <div class="py-4">
        {{ $t("included_colli") }}: {{ GET_COLLI.positionCount }}
      </div>

      <div
        class="w-full bg-white-dark p-3 rounded-md mb-3 border border-transparent hover:border-grey-fontgrey"
        v-for="colliPosition of GET_COLLI.colliPositions"
        :key="colliPosition.id"
        :colliPosition="colliPosition"
      >
        <div class="font-semibold text-primary">
          {{ colliPosition.name }}
        </div>
        <div
          v-if="colliPosition.data != null && colliPosition.data.data != null"
          class="flex flex-row flex-wrap justify-start mt-3 gap-8 "
        >
          <div v-for="(key, value) of colliPosition.data.data" :key="key">
            <!-- class="flex flex-wrap md:flex-nowrap  gap-10 md:gap-24 mt-5 text-xs sm:text-sm " -->
            <div>
              <div class="text-sm text-grey-fontgrey">
                {{ value }}
              </div>
              <div class="text-md ">
                {{ key }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </bottom-up-modal-2>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";

import { useStore } from "vuex";

import BottomUpModal2 from "../../../components/layout/BottomUpModal2.vue";
import {
  COLLI_POSITIONS_TYPES,
  FINDERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
// import { CUSTOMER_colliPositionS_TYPES, STORE_TYPES } from "../../../store/types";
// import { computed } from "@vue/runtime-core";

export default {
  components: {
    BottomUpModal2,
  },

  emits: ["closeModal", "setBack"],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
    },
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      search: "",
      openModal: false,
      showAllPositions: false,
    });

    /*
     * Computeds
     */

    const GET_COLLI_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.COLLI_POSITIONS}/${COLLI_POSITIONS_TYPES.GET_COLLI_POSITIONS}`
      ];
    });
    const GET_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_COLLI}`
      ];
    });

    /** Functions */

    /**
     *  Mounted
     */
    onMounted(() => {});

    return {
      store,
      state,
      route,
      GET_COLLI,
      GET_COLLI_POSITIONS,
    };
  },
};
</script>
