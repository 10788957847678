<template>
  <div class="mt-3 w-full h-px bg-primary-light"></div>
  <div class=" mt-2 flex flex-col gap-4" v-if="showItems">
    <!--- ---------------------- product carrier list for Location ------------------------------>
    <div class=" w-full bg-primary p-3 rounded-md ">
      <div class="flex gap-3 items-center mb-3">
        <div class="font-semibold text-white">{{ product_carrier.name }}</div>
        <div
          class="cursor-pointer"
          v-if="
            product_carrier.colliPositions != null &&
              product_carrier.colliPositions.length != 0
          "
          @click="$emit('showAllPositions')"
        >
          <box-icon :fillClass="'fill-white'" />
        </div>
      </div>

      <div class="flex flex-wrap justify-start gap-8 text-xs sm:text-sm ">
        <div>
          <div class="text-sm text-white">
            {{ $t("recording_time") }}
          </div>
          <div class="text-md text-white " :id="'colli-last-update-' + product_carrier.id">
            {{ product_carrier.lastUpdate ? product_carrier.lastUpdate : "-" }}
          </div>
        </div>
        <div
          v-if="
            product_carrier.data != null &&
              product_carrier.data.data != null &&
              product_carrier.data.dataModel != null &&
              product_carrier.data.dataModel.dataFields != null
          "
          class="flex flex-wrap justify-start gap-8 text-xs sm:text-sm"
        >
          <div
            v-for="dataField of product_carrier.data.dataModel.dataFields"
            :key="dataField"
          >
            <div class=" flex text-sm gap-9 sm:gap-20">
              <div>
                <div class="text-sm text-white">
                  {{ dataField.key }}
                </div>
                <div
                  class="text-md mt-2"
                  v-if="product_carrier.data.data[dataField.key] === true"
                >
                  <right-icon :fillClass="'fill-white'" />
                </div>
                <div
                  class="text-md mt-2"
                  v-else-if="product_carrier.data.data[dataField.key] === false"
                >
                  <false-icon :fillClass="'fill-white'" />
                </div>
                <div class="text-md text-white" v-else>
                  {{
                    product_carrier.data.data[dataField.key] == null ||
                    product_carrier.data.data[dataField.key] == ""
                      ? "-"
                      : product_carrier.data.data[dataField.key]
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--- ______________________ add product carrier  ____________________--->

  <div class="flex flex-col gap-3" v-if="!showItems">
    <!--- ---------------------- Search bar for product carrier  ------------------------------>

    <div>
      <div class="font-semibold text-lg mt-8 mb-4">
        {{ $t("product_carrier") }}
      </div>
      <searchbar
        :id="'search'"
        :label="$t('search')"
        :labelClass="'bg-white'"
        :activeColor="'primary'"
        class="w-1/2 bg-white"
        :placeHolder="$t('designation')"
      />
    </div>
    <!--- ---------------------- product carrier list  ------------------------------>
    <div
      class="bg-white-dark rounded-md w-full p-2"
      v-for="product_carrier of GET_PRODUCT_CARRIERIES"
      :key="product_carrier.id"
      :product_carrier="product_carrier"
    >
      <div class="p-2 flex items-center">
        <div class="flex flex-col">
          <div class=" flex  font-semibold  box-content md:text-md gap-1">
            <div class="font-semibold">
              {{ $t("product_carrier") }}
            </div>
            <div class="font-semibold">
              {{ product_carrier.name }}
            </div>

            {{}}
          </div>
          <div class="text-xs md:text-sm hidden sm:block">
            <div class=" flex items-center text-sm gap-2">
              <div>{{ $t("channel") }}: {{ "23.09.2020" }} |</div>
              <div>{{ $t("group") }}: {{ " Gruppe 2" }}</div>
            </div>
          </div>
        </div>
        <div class="ml-auto md:mr-7">
          <div class="flex items-center space-x-6 ">
            <edit-icon
              class="cursor-pointer"
              @click="$emit('edit_product_carrier')"
            />
            <trash-icon class="cursor-pointer" @click="$emit('deleteColli')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import EditIcon from "../../../components/icons/EditIcon.vue";
import TrashIcon from "../../../components/icons/TrashIcon.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import { FINDERS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
  name: "ProductCarrierPositionsContainer",
  components: { EditIcon, TrashIcon, Searchbar },

  /** EMITS */
  emits: ["deleteColli", "editColli", "showAllPositions"],

  props: {
    showItems: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
      default: null,
    },
    product_carrier: {
      type: Object,
    },
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      openModal: false,
    });

    /*
     * Computeds
     */
    const GET_PRODUCT_CARRIERIES = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_PRODUCT_CARRIERIES}`
      ];
    });

    /** Functions */
    const add_product_carrier = () => {
      state.openModal = true;
    };
    const edit_product_carrier = () => {
      state.openModal = true;
    };

    /**
     *  Mounted
     */
    onMounted(() => {});

    return {
      store,
      state,
      route,
      add_product_carrier,
      edit_product_carrier,
      GET_PRODUCT_CARRIERIES,
    };
  },
});
</script>
