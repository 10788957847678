<template>
    <div
        class=" bg-white rounded-md h-auto flex-1 border border-grey-light p-3 py-4"
    >
        <div class="flex gap-11">
            <div class="flex gap-3 cursor-pointer">
                <location-icon :fillClass="'grey-fontgrey'" />
                <div>{{ position.name }}</div>
            </div>

            <div>{{ $t("product_carrier") }}: {{ position.colliCount }}</div>
            <div
                class="ml-auto cursor-pointer"
                @click="
                    show_all_product_carrier();
                    $emit('showPosition');
                "
            >
                <position-icon />
            </div>
        </div>

        <!-- --------------  All Product_Carrier ------------- -->

        <all-product-carrier v-if="false" :showItems="true" />
    </div>
    <!-- ----------------------------------- -->
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import LocationIcon from "../../../components/icons/LocationIcon.vue";
import PositionIcon from "../../../components/icons/PositionIcon.vue";

import AllProductCarrier from "./AllProductCarrier.vue";

export default defineComponent({
    name: "AllPositionsContainer",
    components: { AllProductCarrier, LocationIcon, PositionIcon },

    props: {
        position: {
            type: Object,
        },
    },

    /** Emits :  */

    emits: ["showAllProductCarrier", "showPosition"],

    setup() {
        const store = useStore();

        /** State */
        const state = reactive({
            showAllProductCarrier: false,
        });

        /** Functions */

        const show_all_product_carrier = () => {
            if (state.showAllProductCarrier == false) {
                state.showAllProductCarrier = true;
            } else {
                state.showAllProductCarrier = false;
            }
        };

        return { store, state, show_all_product_carrier };
    },
});
</script>
