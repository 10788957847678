<template>
  <div>
    <finder-search-box
      :locationFilter="true"
      @filterPosition="
        (value) => {
          state.position = value;
        }
      "
      @update="
        (value) => {
          state.search = value;
        }
      "
    />
    <div
      :id="'position-' + position.id"
      @click="jumpToPosition(position)"
      class="bg-white rounded-md cursor-pointer h-auto flex-1 gap-8 mb-3 hover:border-grey-fontgrey"
      :class="
        state.showPositionId == position.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
      v-for="position of filteredPositions"
      :key="position.id"
      :position="position"
    >
      <finder-locations-info
        @showPositionsChildren="jumpToPosition(position)"
        :position="position"
      />
      <!--  ON ACTIVE   SHOW IF ...  -->
      <div v-if="state.showPositionId == position.id">
        <div class="w-full  p-3">
          <all-product-carrier
            v-for="colli of position.collis"
            :key="colli.id"
            :product_carrier="colli"
            :showItems="true"
            @showAllPositions="show_all_positions(colli)"
          />
        </div>
      </div>
      <div
        class="flex flex-col px-3"
        v-if="state.showPositionId == position.id"
      >
        <all-positions-container
          @showPosition="jumpToPosition(child)"
          class="mb-2"
          v-for="child of position.children"
          :key="child.id"
          :position="child"
        />
      </div>
      <empty-list-feedback
        class="pb-6"
        v-if="
          state.showPositionId == position.id &&
            position.collis != null &&
            position.collis.length == 0 &&
            position.childrenCount == 0
        "
        :text="$t('no_children_for_position')"
      ></empty-list-feedback>
    </div>
  </div>
  <product-carriers-content-modal
    :isVisible="state.openModal"
    @closeModal="state.openModal = false"
  />
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import { FINDERS_TYPES, STORE_TYPES } from "../../../store/types";
import AllPositionsContainer from "../components/AllLocationsContainer.vue";
import AllProductCarrier from "../components/AllProductCarrier.vue";
import FinderLocationsInfo from "../components/FinderLocationsInfo.vue";
import ProductCarriersContentModal from "../modals/ProductCarriersContentModal.vue";
import FinderSearchBox from "../components/FinderSearchBox.vue";

export default defineComponent({
  components: {
    FinderLocationsInfo,
    AllPositionsContainer,
    FinderSearchBox,
    AllProductCarrier,
    EmptyListFeedback,
    ProductCarriersContentModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      showPositionId: null,
      position: "",
      search: "",
      openModal: false,
    });

    /*
     * Computeds
     */
    const GET_FINDER_COLLIS = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_COLLIS}`
      ];
    });
    const GET_FINDER_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_COLLI}`
      ];
    });
    const GET_FINDER_POSITIONS = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_POSITIONS}`
      ];
    });
    const GET_FINDER_POSITION = computed(() => {
      return store.getters[
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.GET_FINDER_POSITION}`
      ];
    });

    const filteredPositions = computed(() => {
      if (GET_FINDER_POSITIONS.value == null) {
        return;
      }
      return GET_FINDER_POSITIONS.value
        .filter((search) => {
          if (state.search == "" || state.search == null) {
            return true;
          }
          if (search.name != null) {
            return search.name.toLowerCase().match(state.search.toLowerCase());
          }
        })
        .filter((position) => {
          if (state.position == "" || state.position == null) {
            return true;
          }
          if (position.name != null) {
            return position.name.match(state.position.name);
          }
        });
    });

    /** Functions */

    const show_all_positions = (colli) => {
      if (state.openModal == false) {
        SET_FINDER_COLLI(colli);
        state.openModal = true;
      } else {
        state.openModal = false;
        SET_FINDER_COLLI({});
      }
    };

    const SET_FINDER_COLLI = (colli) => {
      return store.commit(
        `${STORE_TYPES.FINDERS}/${FINDERS_TYPES.SET_FINDER_COLLI}`,
        colli
      );
    };

    const jumpToPosition = (position) => {
      state.showPositionId =
        state.showPositionId == position.id ? null : position.id;
      setTimeout(() => {
        let element = document.getElementById("position-" + position.id);
        if (
          element != null &&
          element.scrollIntoView != undefined &&
          state.showPositionId == position.id
        ) {
          element.scrollIntoView();
        }
      }, 20);
    };

    return {
      store,
      state,
      route,
      show_all_positions,
      GET_FINDER_COLLIS,
      GET_FINDER_COLLI,
      jumpToPosition,
      GET_FINDER_POSITIONS,
      GET_FINDER_POSITION,
      filteredPositions,
    };
  },
});
</script>
